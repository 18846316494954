import './app.module.scss';
import Plug from './components/plug';

function App() {
  return (
    <div className="app">
      <Plug />
    </div>
  );
}

export default App;
