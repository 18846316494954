import style from './style.module.scss';

import logo from '../../static/images/logo.svg';
import img from '../../static/images/img.png';

const Plug = () => {
  return (
    <div className={style.plug}>
      <div className={style.plug__logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={style.container}>
        <div className={style.plug__text}>
          <p>Мы&nbsp;готовим для вас что-то новенькое</p>
          <p>Мы&nbsp;занимаемся разработкой веб-сервисов и&nbsp;мобильных приложений, но&nbsp;прямо сейчас наш сайт находится в&nbsp;разработке. Пожалуйста, свяжитесь с&nbsp;нами или приходите позже.</p>
          <a href="mailto:sales@deventica.io">СВЯЗАТЬСЯ С НАМИ</a>
        </div>
        <div className={style.plug__image}>
          <img src={img} alt="img" />
        </div>
      </div>
      <div className={style.plug__mobileBtn}>
        <a href="mailto:sales@deventica.io">СВЯЗАТЬСЯ С НАМИ</a>
      </div>
    </div>
  );
}

export default Plug;
